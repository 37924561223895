import { GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const ModalULStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  color: ${GrayColor.Black};
`;

export const ModalLiStyled = styled.li`
  color: ${GrayColor.GrayXDark};
  padding: ${Spacing.XSmall} 0;
  cursor: pointer;
`;
