import { ChatfuelBlockNames } from '@global/utils/chat/chatfuel-blocks-types';
import { IQuestionnaireResultExplanationData } from '@global/utils/domain/questionnaire';

export interface QuestionnaireGraphProps<T> {
  userId?: string;
  data?: T[];
}

export interface QuestionnaireDataMapperInput<T> {
  getPoints: (T) => number;
  questionnaires: Array<T>;
}

export interface QuestionnaireDataMapperOutput {
  date: string;
  points: number;
}

export interface StartSectionProps {
  title: string;
  description: string;
}

export interface QuestionnaireGraphContainerProps {
  data: QuestionnaireDataMapperOutput[];
  threshold: Array<number>;
  initialBlock: ChatfuelBlockNames;
  resultExplanation: IQuestionnaireResultExplanationData;
}

export enum QuestionnaireSectionKind {
  Graph,
  LastResult,
}

export type QuestionnaireSectionProps = QuestionnaireLastResultSectionProps | QuestionnaireGraphSectionProps;

interface QuestionnaireBaseSectionProps {
  info: StartSectionProps;
  userId: string;
  hideData?: boolean;
  noMarginTop?: boolean;
}

export interface QuestionnaireLastResultSectionProps extends QuestionnaireBaseSectionProps {
  kind: QuestionnaireSectionKind.LastResult;
  questionnaireData: {
    result: string;
    date: string;
    resultExplanation: IQuestionnaireResultExplanationData;
    initialBlock: ChatfuelBlockNames;
  };
}

export interface QuestionnaireGraphSectionProps extends QuestionnaireBaseSectionProps {
  kind: QuestionnaireSectionKind.Graph;
  questionnaireData: QuestionnaireGraphContainerProps;
}
