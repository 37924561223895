import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { ChatfuelBlockNames } from '@global/utils/chat/chatfuel-blocks-types';
import { parseDateToDDMMYYYY } from '@global/utils/date/parse-to-string';
import { IQuestionnaireResultExplanationData } from '@global/utils/domain/questionnaire';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { MobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import { Body, SemiBold, Separator } from '@web/atomic';
import { ShimmerBox } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { NativeHelper } from '@web/data/native.helper';
import React from 'react';
import { GatsbyButton } from '../atm.button/button.component';
import { QuestionnaireSectionFooter } from './questionnaire-section-footer';
import { QuestionnaireResultContainerCardContainer } from './questionnaire-section.style';

interface QuestionnaireLastResultContainerProps {
  initialBlock: ChatfuelBlockNames;
  resultExplanation: IQuestionnaireResultExplanationData;
  userId: string;
  date: string;
  result: string;
}

export const QuestionnaireLastResultContainer: React.FunctionComponent<QuestionnaireLastResultContainerProps> = (props) => {
  const { initialBlock, date, result, resultExplanation, userId } = props;
  const openChatfuelBlock = (block: ChatfuelBlockNames) => {
    const data: ChatfuelBroadcastRequest = {
      user: userId,
      blockName: block,
    };
    NativeHelper.postMessage({ type: 'chatfuelBroadcast', data });
  };

  if (result === null) {
    return (
      <MobilePlaceholder emoji="🧐" description="Nenhum dado preenchido. Que tal preencher pela primeira vez?">
        <GatsbyButton expanded onClick={() => openChatfuelBlock(initialBlock)}>
          Preencher autoavaliação
        </GatsbyButton>
      </MobilePlaceholder>
    );
  }

  const loading = isNullOrUndefined(result);

  return (
    <>
      {loading ? (
        <ShimmerBox width={'100%'} height={'150px'} />
      ) : (
        <QuestionnaireResultContainerCardContainer padding minHeight={1}>
          <Body>
            {' '}
            <SemiBold> {result} </SemiBold>
          </Body>
          <Body>({parseDateToDDMMYYYY(new Date(date))}) </Body>
          <Separator />
        </QuestionnaireResultContainerCardContainer>
      )}
      <QuestionnaireSectionFooter resultExplanation={resultExplanation} onStartQuestionnaireClick={() => openChatfuelBlock(initialBlock)} />
    </>
  );
};
