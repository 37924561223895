import { IQuestionnaireResultExplanationData } from '@global/utils/domain/questionnaire';
import { Body, H4, Modal, Separator } from '@web/atomic';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import React from 'react';
import { GatsbyButton } from '../atm.button/button.component';
import { ModalList } from '../mol.modal-list/modal-list.component';

export interface IQuestionnaireSectionFooterProps {
  resultExplanation: IQuestionnaireResultExplanationData;
  onStartQuestionnaireClick: () => void;
}

export const QuestionnaireSectionFooter: React.FunctionComponent<IQuestionnaireSectionFooterProps> = (props) => {
  const {
    value: showResultExplanation,
    setTrue: setTrueShowResultExplanation,
    setFalse: setFalseShowResultExplanation,
  } = useBooleanState(false);

  return (
    <>
      <Separator />
      <ButtonsQuestionnaire onStartQuestionnaireClick={props.onStartQuestionnaireClick} onShowMoreClick={setTrueShowResultExplanation} />
      <ResultExplanationModal
        opened={showResultExplanation}
        resultExplanation={props.resultExplanation}
        onClose={setFalseShowResultExplanation}
      />
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ButtonsQuestionnaireProps {
  onStartQuestionnaireClick: () => void;
  onShowMoreClick: () => void;
}
export const ButtonsQuestionnaire: React.FunctionComponent<ButtonsQuestionnaireProps> = ({
  onStartQuestionnaireClick,
  onShowMoreClick,
}) => {
  return (
    <>
      <GatsbyButton expanded onClick={onStartQuestionnaireClick}>
        {' '}
        Preencher autoavaliação{' '}
      </GatsbyButton>
      <Separator />
      <GatsbyButton kind="secondary" expanded onClick={onShowMoreClick}>
        Entender resultado
      </GatsbyButton>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ResultExplanationModalProps {
  opened: boolean;
  onClose?: () => void;
  resultExplanation: IQuestionnaireResultExplanationData;
}

export const ResultExplanationModal: React.FunctionComponent<ResultExplanationModalProps> = ({ opened, onClose, resultExplanation }) => {
  return (
    <Modal opened={opened} onClose={onClose}>
      <Separator />
      <H4 kind="default">{resultExplanation.title}</H4>
      <Body kind="default">{resultExplanation.description}</Body>
      <Separator />
      <ModalList data={resultExplanation.results} />
      <Separator />
    </Modal>
  );
};
