import { QuestionnaireGraphContainer } from '@lp-root/src/components/mol.questionnaire/questionnaire-graph-container.component';
import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import * as React from 'react';
import { QuestionnaireGraphSectionProps } from './questionnaires-section.model';

export const QuestionnaireGraphSection: React.FunctionComponent<QuestionnaireGraphSectionProps> = (props) => {
  const { info, questionnaireData, userId, hideData } = props;
  return (
    <>
      <StartSection noMarginTop={props.noMarginTop} title={info.title} description={info.description} />
      <QuestionnaireGraphContainer
        data={hideData ? null : questionnaireData.data}
        threshold={questionnaireData.threshold}
        initialBlock={questionnaireData.initialBlock}
        resultExplanation={questionnaireData.resultExplanation}
        userId={userId}
      />
    </>
  );
};
