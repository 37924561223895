import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import {
  apneaQuestionnaireInitialBlockName,
  apneaQuestionnaireResultExplanationData,
  computeApneaQuestionnaireResult,
} from '@global/utils/domain/apnea-questionnaire';
import {
  computeEpworthPoints,
  epworthInitialBlockName,
  epworthResultExplanationData,
  epworthThreshold,
} from '@global/utils/domain/epworth';
import { computeGad7Points, gad7InitialBlockName, gad7ResultExplanationData, gad7Threshold } from '@global/utils/domain/gad';
import { computeIsiPoints, isiInitialBlockName, isiResultExplanationData, isiThreshold } from '@global/utils/domain/isi';
import {
  computeKuppermanPoints,
  kuppermanInitialBlockName,
  kuppermanResultExplanationData,
  kuppermanThreshold,
} from '@global/utils/domain/kupperman';
import {
  computePersonalityQuestionnaireResult,
  personalityQuestionnaireInitialBlockName,
  personalityQuestionnaireResultExplanationData,
} from '@global/utils/domain/personality-questionnaire';
import { computePhq9Points, phq9InitialBlockName, phq9ResultExplanationData, phq9Threshold } from '@global/utils/domain/phq';
import { computePsqiPoints, psqiInitialBlockName, psqiResultExplanationData, psqiThreshold } from '@global/utils/domain/psqi';
import {
  MunichChronotypeOutput_Result,
  mapChronotypeToString,
  munichChronotypeCoreInitialBlockName,
  munichChronotypeCoreQuestionnaireResultExplanationData,
} from '@global/utils/domain/munich-chronotype';
import { NativeConfigWebviewExtraDataVersion } from '@global/utils/native/native-config';
import { Epworth, Gad7, Isi, Kupperman, Phq9, Psqi, QuestionnairesGraphQueryQuery, Stop_Bang } from '@global/utils/remote-graphql-types';
import { NativeHelper } from '@web/data/native.helper';
import { QuestionnaireDataMapperInput, QuestionnaireSectionKind, QuestionnaireSectionProps } from './questionnaires-section.model';
import * as CompareVersions from 'compare-versions';
import { StopBangResult, computeStopbangPoints, stopbangInitialBlockName, stopbangResultExplanationData } from '@global/utils';

export function mapToQuestionnaireData<T extends { created_at: string }>(props: QuestionnaireDataMapperInput<T>) {
  if (!props.questionnaires?.length) return [];

  const orderedQuestionnaires = props.questionnaires.slice().sort((a, b) => {
    return a.created_at >= b.created_at ? 1 : -1;
  });

  return orderedQuestionnaires?.map((q) => {
    return {
      date: parseDateToShortString(parseToDate(q.created_at)),
      points: props.getPoints(q),
    };
  });
}

export const mapToQuestionnaireGraphData = (data: QuestionnairesGraphQueryQuery | null, userId: string): QuestionnaireSectionProps[] => {
  const extraData = NativeHelper.extraData as NativeConfigWebviewExtraDataVersion;
  // TODO: remove this (feature flag using vertion) after this feature is launched for 1 month
  // ps: it was built on 26/10/2023
  const showApneaAndMunichAndEpworthAndPsqiAndPersonality =
    extraData?.platform &&
    extraData?.version &&
    ((extraData.platform === 'ios' && CompareVersions.compare(extraData.version, '4.6.18', '>=')) ||
      (extraData.platform === 'android' && CompareVersions.compare(extraData.version, '4.6.0-alpha.20', '>=')));
  const showStopband =
    extraData?.platform &&
    extraData?.version &&
    ((extraData.platform === 'ios' && CompareVersions.compare(extraData.version, '4.7.10', '>=')) ||
      (extraData.platform === 'android' && CompareVersions.compare(extraData.version, '4.6.10', '>=')));

  const personalityQuestionnaireAnalysis = computePersonalityQuestionnaireResult(data?.personality_questionnaire?.[0]);
  const questinnaireGraphData = [
    // APNEA
    showApneaAndMunichAndEpworthAndPsqiAndPersonality &&
      ({
        questionnaireData: {
          date: data?.apnea_questionnaire?.[0]?.created_at,
          result: data?.apnea_questionnaire?.length === 0 ? null : computeApneaQuestionnaireResult(data?.apnea_questionnaire?.[0]),
          initialBlock: apneaQuestionnaireInitialBlockName,
          resultExplanation: apneaQuestionnaireResultExplanationData,
        },
        kind: QuestionnaireSectionKind.LastResult,
        info: {
          title: apneaQuestionnaireResultExplanationData.title,
          description: apneaQuestionnaireResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
    // ISI
    {
      questionnaireData: {
        data: mapToQuestionnaireData<Isi>({ getPoints: computeIsiPoints, questionnaires: data?.isi }),
        threshold: isiThreshold,
        initialBlock: isiInitialBlockName,
        resultExplanation: isiResultExplanationData,
      },
      kind: QuestionnaireSectionKind.Graph,
      info: {
        title: isiResultExplanationData.title,
        description: isiResultExplanationData.description,
      },
      userId: userId,
    } satisfies QuestionnaireSectionProps,
    // PSQI
    showApneaAndMunichAndEpworthAndPsqiAndPersonality &&
      ({
        questionnaireData: {
          data: mapToQuestionnaireData<Omit<Psqi, 'user' | 'updated_at' | 'age' | 'q5j_comment'>>({
            getPoints: computePsqiPoints,
            questionnaires: data?.psqi,
          }),
          threshold: psqiThreshold,
          initialBlock: psqiInitialBlockName,
          resultExplanation: psqiResultExplanationData,
        },
        kind: QuestionnaireSectionKind.Graph,
        info: {
          title: psqiResultExplanationData.title,
          description: psqiResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
    // STOP_BANG
    showStopband &&
      ({
        questionnaireData: {
          data: mapToQuestionnaireData<Omit<Stop_Bang, 'user' | 'updated_at'>>({
            getPoints: computeStopbangPoints,
            questionnaires: data?.stop_bang,
          }),
          threshold: [StopBangResult.LowRisk, StopBangResult.IntermediateRisk, StopBangResult.HighRisk],
          initialBlock: stopbangInitialBlockName,
          resultExplanation: stopbangResultExplanationData,
        },
        kind: QuestionnaireSectionKind.Graph,
        info: {
          title: stopbangResultExplanationData.title,
          description: stopbangResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
    // EPWORTH
    showApneaAndMunichAndEpworthAndPsqiAndPersonality &&
      ({
        questionnaireData: {
          data: mapToQuestionnaireData<Omit<Epworth, 'user' | 'updated_at'>>({
            getPoints: computeEpworthPoints,
            questionnaires: data?.epworth,
          }),
          threshold: epworthThreshold,
          initialBlock: epworthInitialBlockName,
          resultExplanation: epworthResultExplanationData,
        },
        kind: QuestionnaireSectionKind.Graph,
        info: {
          title: epworthResultExplanationData.title,
          description: epworthResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
    // MUNICH
    showApneaAndMunichAndEpworthAndPsqiAndPersonality &&
      ({
        questionnaireData: {
          date: data?.munich_chronotype_core?.[0]?.created_at,
          result:
            data?.munich_chronotype_core?.length === 0
              ? null
              : mapChronotypeToString(data?.munich_chronotype_core?.[0]?.result as MunichChronotypeOutput_Result),
          initialBlock: munichChronotypeCoreInitialBlockName,
          resultExplanation: munichChronotypeCoreQuestionnaireResultExplanationData,
        },
        kind: QuestionnaireSectionKind.LastResult,
        info: {
          title: munichChronotypeCoreQuestionnaireResultExplanationData.title,
          description: munichChronotypeCoreQuestionnaireResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
    // GAD7
    {
      questionnaireData: {
        data: mapToQuestionnaireData<Omit<Gad7, 'user' | 'updated_at'>>({ getPoints: computeGad7Points, questionnaires: data?.gad7 }),
        threshold: gad7Threshold,
        initialBlock: gad7InitialBlockName,
        resultExplanation: gad7ResultExplanationData,
      },
      kind: QuestionnaireSectionKind.Graph,
      info: {
        title: gad7ResultExplanationData.title,
        description: gad7ResultExplanationData.description,
      },
      userId: userId,
    } satisfies QuestionnaireSectionProps,
    // PHQ9
    {
      questionnaireData: {
        data: mapToQuestionnaireData<Omit<Phq9, 'user' | 'updated_at'>>({ getPoints: computePhq9Points, questionnaires: data?.phq9 }),
        threshold: phq9Threshold,
        initialBlock: phq9InitialBlockName,
        resultExplanation: phq9ResultExplanationData,
      },
      kind: QuestionnaireSectionKind.Graph,
      info: {
        title: phq9ResultExplanationData.title,
        description: phq9ResultExplanationData.description,
      },
      userId: userId,
    } satisfies QuestionnaireSectionProps,
    // KUPPERMAN
    {
      questionnaireData: {
        data: mapToQuestionnaireData<Omit<Kupperman, 'user' | 'updated_at'>>({
          getPoints: computeKuppermanPoints,
          questionnaires: data?.kupperman,
        }),
        threshold: kuppermanThreshold,
        initialBlock: kuppermanInitialBlockName,
        resultExplanation: kuppermanResultExplanationData,
      },
      kind: QuestionnaireSectionKind.Graph,
      info: {
        title: kuppermanResultExplanationData.title,
        description: kuppermanResultExplanationData.description,
      },
      userId: userId,
    } satisfies QuestionnaireSectionProps,
    // PERSONALITY
    showApneaAndMunichAndEpworthAndPsqiAndPersonality &&
      ({
        questionnaireData: {
          date: data?.personality_questionnaire?.[0]?.created_at,
          result:
            data?.personality_questionnaire?.length === 0
              ? null
              : personalityQuestionnaireAnalysis &&
                `Conscienciosidade: ${personalityQuestionnaireAnalysis.conscientiousness}\nAmabilidade: ${personalityQuestionnaireAnalysis.agreableness}\nExtroversão: ${personalityQuestionnaireAnalysis.extroversion}\n Abertura a experiências: ${personalityQuestionnaireAnalysis.openess}\nNeuroticismo: ${personalityQuestionnaireAnalysis.neuroticism}`,
          initialBlock: personalityQuestionnaireInitialBlockName,
          resultExplanation: personalityQuestionnaireResultExplanationData,
        },
        kind: QuestionnaireSectionKind.LastResult,
        info: {
          title: personalityQuestionnaireResultExplanationData.title,
          description: personalityQuestionnaireResultExplanationData.description,
        },
        userId: userId,
      } satisfies QuestionnaireSectionProps),
  ].filter((item) => item) as QuestionnaireSectionProps[];
  return questinnaireGraphData;
};
