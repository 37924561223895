import { ColorBackground } from '@lp-root/src/components/atm.background/background.styled';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { QuestionnaireGraphSection } from '@lp-root/src/components/org.questionnaires/questionnaire-graph-section.component';
import { QuestionnaireLastResultSection } from '@lp-root/src/components/org.questionnaires/questionnaire-last-result-section.component';
import { QuestionnaireSectionKind } from '@lp-root/src/components/org.questionnaires/questionnaires-section.model';
import { mapToQuestionnaireGraphData } from '@lp-root/src/components/org.questionnaires/questionnaires-utils';
import { QuestionnairesGraphQuery } from '@lp-root/src/data/graphql/hasura/questionnaires-graph.query.hasura';
import { BrandColor, Col, FaIcon, Grid, Row, Separator } from '@web/atomic';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { NativeHelper } from '@web/data/native.helper';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React from 'react';
import { PageProps } from '../../utils/local-types';

const QuestionnaireChart: React.FunctionComponent<PageProps> = (props) => {
  const userId = NativeHelper.userId;

  const { data, error, loading } = useQueryCustom(QuestionnairesGraphQuery, {
    variables: { id: userId },
  });

  const questionnaireData = mapToQuestionnaireGraphData(data, userId);

  return (
    <WebviewLayout hideThemeChanger={true}>
      <ColorBackground color={BrandColor.Charade}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
            title: 'Gráficos de avaliação da saúde mental e insônia',
          }}
        />
        <Grid>
          <LoadingState loading={loading} error={!!error} data={!!data}>
            <LoadingState.Shimmer>
              {questionnaireData.map((props, i) => {
                return (
                  <Row key={i}>
                    <Col xs={12}>
                      {props.kind === QuestionnaireSectionKind.Graph ? (
                        <QuestionnaireGraphSection {...props} hideData={true} noMarginTop={i === 0} />
                      ) : (
                        <QuestionnaireLastResultSection {...props} noMarginTop={i === 0} />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </LoadingState.Shimmer>
            <LoadingState.Error>
              <Separator />
              <Placeholder
                icon={<FaIcon.FlashAlert size="9x" />}
                title={'Erro ao pegar seus dados'}
                description="Tente novamente mais tarde"
              />
            </LoadingState.Error>
            {questionnaireData.map((props, i) => {
              return (
                <Row key={i}>
                  <Col xs={12}>
                    {props.kind === QuestionnaireSectionKind.Graph ? (
                      <QuestionnaireGraphSection {...props} noMarginTop={i === 0} />
                    ) : (
                      <QuestionnaireLastResultSection {...props} noMarginTop={i === 0} />
                    )}
                  </Col>
                </Row>
              );
            })}
          </LoadingState>
        </Grid>
        <Separator />
      </ColorBackground>
    </WebviewLayout>
  );
};

export default QuestionnaireChart;

export const query = graphql`
  query GraficoQuestionarios {
    site {
      ...SiteUrl
    }
  }
`;
