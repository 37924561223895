import { IQuestionnaireChartProps, QuestionnaireGraph } from '@component/charts/questionnaires.graph.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { ChatfuelBlockNames } from '@global/utils/chat/chatfuel-blocks-types';
import { IQuestionnaireResultExplanationData } from '@global/utils/domain/questionnaire';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { MobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import { ShimmerBox } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { NativeHelper } from '@web/data/native.helper';
import React from 'react';
import { GatsbyButton } from '../atm.button/button.component';
import { QuestionnaireSectionFooter } from './questionnaire-section-footer';
import { QuestionnaireResultContainerCardContainer } from './questionnaire-section.style';

interface QuestionnaireGraphContainerProps extends IQuestionnaireChartProps {
  initialBlock: ChatfuelBlockNames;
  resultExplanation: IQuestionnaireResultExplanationData;
  userId: string;
}

export const QuestionnaireGraphContainer: React.FunctionComponent<QuestionnaireGraphContainerProps> = (props) => {
  const { initialBlock, data, threshold, height, removeAnimation, resultExplanation, userId } = props;
  const openChatfuelBlock = (block: ChatfuelBlockNames) => {
    const data: ChatfuelBroadcastRequest = {
      user: userId,
      blockName: block,
    };
    NativeHelper.postMessage({ type: 'chatfuelBroadcast', data });
  };

  if (data?.length === 0) {
    return (
      <MobilePlaceholder emoji="🧐" description="Nenhum dado preenchido. Que tal preencher pela primeira vez?">
        <GatsbyButton expanded onClick={() => openChatfuelBlock(initialBlock)}>
          Preencher autoavaliação
        </GatsbyButton>
      </MobilePlaceholder>
    );
  }

  const loading = isNullOrUndefined(data);

  return (
    <>
      {loading ? (
        <ShimmerBox width={'100%'} height={'150px'} />
      ) : (
        <QuestionnaireResultContainerCardContainer>
          <QuestionnaireGraph data={data} threshold={threshold} height={height} removeAnimation={removeAnimation} />
          <QuestionnaireSectionFooter
            resultExplanation={resultExplanation}
            onStartQuestionnaireClick={() => openChatfuelBlock(initialBlock)}
          />
        </QuestionnaireResultContainerCardContainer>
      )}
    </>
  );
};
