import React, { useContext } from 'react';
import { Brush, CartesianGrid, Line, LineChart, LineProps, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartWrapperStyled } from './graph.styled';
import { CHART_COLORS, numberOfDaysToShowInitiallyOnGraph } from '@component/charts/utils';
import { AppThemeContext, BrandColor, GrayColor, LightColor } from '@web/atomic';

interface dataQuestionnaireChart {
  date: string;
  points: number;
}

export interface IQuestionnaireChartProps {
  data: dataQuestionnaireChart[];
  threshold: Array<number>;
  height?: number;
  removeAnimation?: boolean;
}

export const QuestionnaireGraph: React.FunctionComponent<IQuestionnaireChartProps> = (props) => {
  const height = props.height || 300;
  const { theme } = useContext(AppThemeContext);

  const lineProps: Partial<LineProps> = {
    type: 'monotoneX',
    dot: true,
    fill: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
    stroke: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
  };

  const formatter = (value, name, props) => {
    const formattedName = labelDictionary[name];
    return [value, formattedName, props];
  };

  return (
    <>
      <ChartWrapperStyled height={height}>
        <ResponsiveContainer>
          <LineChart data={props.data} margin={{ top: 0, right: 30, bottom: 0, left: -30 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray} />
            <YAxis
              type="number"
              dataKey="points"
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              ticks={props.threshold}
            />

            <Tooltip formatter={formatter} contentStyle={{ backgroundColor: 'black' }} itemStyle={{ color: '#fff' }} />
            {props.data.length > numberOfDaysToShowInitiallyOnGraph && (
              <Brush dataKey="name" height={30} startIndex={props.data.length - numberOfDaysToShowInitiallyOnGraph} />
            )}
            <Line isAnimationActive={!props.removeAnimation} dataKey="date" {...lineProps} ref={null} />
            <Line
              isAnimationActive={!props.removeAnimation}
              dataKey="points"
              {...lineProps}
              ref={null}
              dot={<CustomizedDot threshold={props.threshold} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
    </>
  );
};

interface CustomizedDotProps {
  threshold: Array<number>;
  // this prop is set by the recharts
  cx?: number;
  // this prop is set by the recharts
  cy?: number;
  // this prop is set by the recharts
  payload?: dataQuestionnaireChart;
}

const CustomizedDot: React.FunctionComponent<CustomizedDotProps> = (props) => {
  const { cx, cy, threshold, payload } = props;

  const colorpallet =
    threshold.length >= 4
      ? [CHART_COLORS[5], CHART_COLORS[3], CHART_COLORS[2], CHART_COLORS[14], CHART_COLORS[1]]
      : [CHART_COLORS[3], CHART_COLORS[2], CHART_COLORS[14], CHART_COLORS[1]];

  if (payload.points <= threshold[0]) return HappyEmoticon(cx, cy, colorpallet[0]);

  if (payload.points <= threshold[1]) return NeutralEmoticon(cx, cy, colorpallet[1]);

  if (payload.points <= threshold[2]) return NeutralEmoticon(cx, cy, colorpallet[2]);

  if (payload.points > threshold[threshold.length - 1]) return UnhappyEmoticon(cx, cy, colorpallet[colorpallet.length - 1]);

  return UnhappyEmoticon(cx, cy, colorpallet[3]);
};

const labelDictionary = {
  points: 'Pontos',
  date: 'Data',
};

const UnhappyEmoticon = (cx: number, cy: number, color: string): JSX.Element => {
  return (
    <svg x={cx - 7.5} y={cy - 7.5} width={15} height={15} fill={color} viewBox="0 0 1024 1024">
      <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
    </svg>
  );
};

const HappyEmoticon = (cx: number, cy: number, color: string): JSX.Element => {
  return (
    <svg x={cx - 7.5} y={cy - 7.5} width={15} height={15} fill={color} viewBox="0 0 1024 1024">
      <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
    </svg>
  );
};

const NeutralEmoticon = (cx: number, cy: number, color: string): JSX.Element => {
  return (
    <svg x={cx - 7.5} y={cy - 7.5} width={15} height={15} fill={color}>
      <path d="M7.843 15.106a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM5.263 4.347c-.595 0-1.078.494-1.078 1.104 0 .61.483 1.103 1.078 1.103A1.09 1.09 0 006.34 5.451c0-.61-.482-1.104-1.077-1.104zm5.204.012c-.595 0-1.077.494-1.077 1.104 0 .609.482 1.103 1.077 1.103a1.09 1.09 0 001.078-1.103c0-.61-.483-1.104-1.078-1.104zm1.212 6.406c.242-.03-2.465-.067-3.8-.067-1.294 0-3.349-.044-3.74-.037-.393.007-.238.884-.052.889 1.797.048 2.025.105 3.75.105 1.311 0 3.084-.004 3.845-.029.087-.003-.003-.861-.003-.861z" />
    </svg>
  );
};
