import { Personality_Questionnaire } from '../remote-graphql-types';
import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

export interface PersonalityQuestionnaireResult {
  // {{perso_q1_number}}+{{perso_q6_number}}+{{perso_q11_number}}+{{perso_q16_number}}
  extroversion: number;
  // {{perso_q2_number}}+{{perso_q7_number}}+{{perso_q12_number}}+{{perso_q17_number}}
  conscientiousness: number;
  // {{perso_q3_number}}+{{perso_q9_number}}+{{perso_q14_number}}+{{perso_q18_number}}
  neuroticism: number;
  // {{perso_q4_number}}+{{perso_q8_number}}+{{perso_q13_number}}+{{perso_q19_number}}
  agreableness: number;
  // {{perso_q5_number}}+{{perso_q10_number}}+{{perso_q15_number}}+{{perso_q20_number}}
  openess: number;
}

export const computePersonalityQuestionnaireResult = (
  personalityQuestionnaire: Omit<Personality_Questionnaire, 'user' | 'updated_at'>
): PersonalityQuestionnaireResult => {
  if (!personalityQuestionnaire) return null;
  return {
    extroversion: personalityQuestionnaire.q1 + personalityQuestionnaire.q6 + personalityQuestionnaire.q11 + personalityQuestionnaire.q16,
    conscientiousness:
      personalityQuestionnaire.q2 + personalityQuestionnaire.q7 + personalityQuestionnaire.q12 + personalityQuestionnaire.q17,
    neuroticism: personalityQuestionnaire.q3 + personalityQuestionnaire.q9 + personalityQuestionnaire.q14 + personalityQuestionnaire.q18,
    agreableness: personalityQuestionnaire.q4 + personalityQuestionnaire.q8 + personalityQuestionnaire.q13 + personalityQuestionnaire.q19,
    openess: personalityQuestionnaire.q5 + personalityQuestionnaire.q10 + personalityQuestionnaire.q15 + personalityQuestionnaire.q20,
  };
};

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '653815ac87d9667ef1aed4c7';
export const personalityQuestionnaireInitialBlockName: ChatfuelBlockNames = 'personality_questionnaire_youpage_entrance';

export const personalityQuestionnaire = { name: 'Personalidade', redirect: redirectBlock };

export const personalityQuestionnaireResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Questionário de personalidade',
  description: 'Escala de diferencial semântico dos marcadores reduzidos da personalidade',
  results: [
    {
      title: 'Conscienciosidade',
      description: 'avalia o grau de responsabildiade, organização e disciplina',
    },
    {
      title: '≤ 22',
      description:
        'O fator conscienciosidade menor que a média indica que você provavelmente prefere não ter uma rotina e que planejar suas atividades ou cuidado com a saúde não seja uma prioridade.',
    },
    {
      title: '> 22 e < 27',
      description:
        'O fator conscienciosidade na média indica que você busca disciplina, planejamento de metas, cuidado com a saúde e ter responsabilidade',
    },
    {
      title: '≥ 27 ',
      description:
        'O fator conscienciosidade maior que a média pode dificultar algumas coisas, quando há uma certa obsessão ou rigidez para se adaptar com imprevistos',
    },
    {
      title: 'Amabilidade',
      description: 'compaixão, cooperação e empatia nas relações interpessoais',
    },
    {
      title: '≤ 23',
      description:
        'O fator amabilidade menor que a média indica que você provavelmente prefira ficar mais distante ou que sinta dificuldade para se colocar no lugar dos outros. ',
    },
    {
      title: '> 23 e < 27',
      description:
        'O fator amabilidade na média indica que você provavelmente estabeleça boas relações interpessoais e valorize a confiança e generosidade. ',
    },
    {
      title: '≥ 27 ',
      description:
        'O fator amabilidade maior que a média indica que você provavelmente seja uma pessoa afetiva e preocupadas com o bem-estar dos outros.',
    },
    {
      title: 'Extroversão',
      description: 'sociabilidade, assertividade e busca de interações sociais',
    },
    {
      title: '≤ 13',
      description:
        'O fator extroversão menor que a média indica que você provavelmente prefira um convívio social intenso, precisando de interações com outras pessoas.',
    },
    {
      title: '> 13 e < 25',
      description:
        'O fator extroversão na média indica que você provavelmente prefira variar entre ter ou não convívio social, a depender do contexto',
    },
    {
      title: '≥ 25',
      description:
        'O fator extroversão acima da média indica que você provavelmente prefira um convívio social intenso, precisando de interações com outras pessoas.',
    },
    {
      title: 'Abertura a experiências',
      description: 'disposição a novas experiências, desafios e ideias',
    },
    {
      title: '≤ 19',
      description:
        'O fator abertura a experiências menor que a média indica que você provavelmente prefira manter uma rotina e não se expor a situações novas e desafiadoras.',
    },
    {
      title: '> 19 e < 25',
      description:
        'O fator abertura a experiências na média indica que você provavelmente pense antes de se jogar em novas experiências, a depender do contexto e situação.',
    },
    {
      title: '≥ 25',
      description:
        'O fator abertura a experiências maior que a média indica que você provavelmente goste de se jogar em novas experiências.',
    },
    {
      title: 'Neuroticismo',
      description: 'Probabilidade de vivenciar emoções negativas como raiva, ansiedade e depressão',
    },
    {
      title: '≤ 10',
      description: 'Fator neuroticismo menor que a média, mais baixo do que tende a ser para a maior parte das pessoas.',
    },
    {
      title: '> 10 e < 18',
      description: 'Fator neuroticismo na média, assim como tende a ser para a maior parte das pessas. ',
    },
    {
      title: '≥ 18',
      description:
        'Neuroticismo se relaciona com mais sofrimento, por uma tendência à vivência de emoções negativas de forma intensa e frequente.',
    },
  ],
};
