import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import * as React from 'react';
import { QuestionnaireLastResultContainer } from '../mol.questionnaire/questionnaire-last-result-container.component';
import { QuestionnaireLastResultSectionProps } from './questionnaires-section.model';

export const QuestionnaireLastResultSection: React.FunctionComponent<QuestionnaireLastResultSectionProps> = (props) => {
  const { info, questionnaireData, userId } = props;
  return (
    <>
      <StartSection noMarginTop={props.noMarginTop} title={info.title} description={info.description} />
      <QuestionnaireLastResultContainer
        initialBlock={questionnaireData.initialBlock}
        date={questionnaireData.date}
        result={questionnaireData.result}
        resultExplanation={questionnaireData.resultExplanation}
        userId={userId}
      />
    </>
  );
};
