import { ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { H2 } from '@web/atomic/legacy/atm.typography/typography.component.style';
import * as React from 'react';
import { BodySecondaryStartSection, StartSectionStyled } from './section-start.style';

interface StartSectionProps {
  title: string;
  description?: string;
  noMarginTop?: boolean;
}

export const StartSection: React.FunctionComponent<StartSectionProps> = (props) => {
  return (
    <StartSectionStyled noMarginTop={props.noMarginTop}>
      <H2>{props.title}</H2>
      {props.description && <BodySecondaryStartSection>{props.description}</BodySecondaryStartSection>}
    </StartSectionStyled>
  );
};

export const ShimmeStartSection: React.FunctionComponent<Partial<StartSectionProps>> = (props) => {
  return (
    <StartSectionStyled noMarginTop={props.noMarginTop}>
      <H2>
        <ShimmerText />
      </H2>
      {props.description && (
        <BodySecondaryStartSection>
          <ShimmerText height="8px" />
        </BodySecondaryStartSection>
      )}
    </StartSectionStyled>
  );
};
