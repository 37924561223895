import React from 'react';
import { ModalLiStyled, ModalULStyled } from './modal-list.styled';
import { ModalLiProps, ModalListProps } from './modal-list.model';

export const ModalList: React.FunctionComponent<ModalListProps> = ({ data }) => {
  return (
    <ModalUL>
      {data.map((data, index) => {
        return <ModalLi title={data.title} description={data.description} key={index} />;
      })}
    </ModalUL>
  );
};

const ModalUL = ({ children }) => {
  return <ModalULStyled>{children}</ModalULStyled>;
};

const ModalLi: React.FunctionComponent<ModalLiProps> = ({ title, description }) => {
  return (
    <ModalLiStyled>
      <strong> {title}:</strong> {description}
    </ModalLiStyled>
  );
};
