import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { QuestionnairesGraphQueryQuery, QuestionnairesGraphQueryQueryVariables } from '@global/utils/remote-graphql-types';

export const QuestionnairesGraphQuery: TypedDocumentNode<QuestionnairesGraphQueryQuery, QuestionnairesGraphQueryQueryVariables> = gql`
  query QuestionnairesGraphQuery($id: String!) {
    gad7(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      user_id
    }
    phq9(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      q8
      q9
      user_id
    }
    isi(where: { user_id: { _eq: $id } }) {
      age
      created_at
      id
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      user_id
    }
    kupperman(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q10
      q11
      q12
      q13
      q2
      q3
      q4
      q5
      q6
      q7
      q8
      q9
      user_id
    }
    psqi(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q2
      q3
      q4
      q5a
      q5b
      q5c
      q5d
      q5e
      q5f
      q5g
      q5h
      q5i
      q5j
      q6
      q7
      q8
      q9
      user_id
    }
    personality_questionnaire(where: { user_id: { _eq: $id } }, limit: 1, order_by: { created_at: desc }) {
      created_at
      id
      q1
      q10
      q11
      q12
      q13
      q14
      q15
      q16
      q17
      q18
      q19
      q2
      q20
      q3
      q4
      q5
      q6
      q7
      q8
      q9
      user_id
    }
    stop_bang(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      q8
      q9
      user_id
    }
    epworth(where: { user_id: { _eq: $id } }) {
      created_at
      id
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      q8
      user_id
    }
    apnea_questionnaire(where: { user_id: { _eq: $id } }, limit: 1, order_by: { created_at: desc }) {
      created_at
      first_analysis
      id
      second_analysis
      user_id
    }
    munich_chronotype_core(where: { user_id: { _eq: $id } }, limit: 1, order_by: { created_at: desc }) {
      created_at
      id
      result
      user_id
    }
  }
`;
