import { hexToRGBA } from '@global/utils/ui/color-fn';
import { Alpha, BodySecondary, Border, FontSize, GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const StartSectionStyled = styled.div<{ noMarginTop: boolean }>`
  margin-bottom: ${Spacing.Medium};
  border-top: ${(props) => (props.noMarginTop ? 0 : Border.Width)} solid ${hexToRGBA(GrayColor.White, Alpha.L2)};
  margin-top: ${(props) => (props.noMarginTop ? 0 : Spacing.Small)};
  padding-top: ${(props) => (props.noMarginTop ? 0 : Spacing.Small)};
`;

export const BodySecondaryStartSection = styled(BodySecondary)`
  font-size: ${FontSize.XXSmall};
`;
